import React from "react"
import Spend from "../../components/body/pages/en-ng/spend"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const CardsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/spend"}
      title="Spend with Kuda | The Money App for Africans"
      description="Your Kuda account comes with 25 free transfers to other banks every month. That’s up to 15,000 naira saved on transfers every year. Join Kuda today!"
    />
    <Spend />
  </Layout>
)

export default CardsPage
