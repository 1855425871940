import React, { useEffect, Fragment } from "react";
import BelowFoldCards from "../../general/belowFoldCards";
import CTA from "../../general/cta";
import EntryFold from "../../general/entryFold";
import Card from "../../../../../assets/img/card-icon.inline.svg";
import Pay from "../../../../../assets/img/pay-icon.inline.svg";
import PaymentLink from "../../../../../assets/img/payment-link-icon.inline.svg";
import ImageTextLeft from "../../general/imageTextLeft";
import ImageTextRight from "../../general/imageTextRight";
import FallbackSpendHeader from "../../../../../assets/img/blur/kuda-new-spend.inline.svg";
import SendFreeIllustration from "../../../../../assets/img/send-free--illustration.inline.svg";
import CardNewIllustration from "../../../../../assets/img/cards-entry-illustration.inline.svg";
import CashPayIllustration from "../../../../../assets/img/cashPayIllustration.inline.svg";
import Username from "../../../../../assets/img/username.inline.svg";
import PayID from "../../../../../assets/img/pay-id--illustration.inline.svg";

import { scrollToElement } from "../../../../utility/utils";
import { StaticImage } from "gatsby-plugin-image";
import MoreForYouComponent from "../../general/more-for-you";


import KudaAirtimeIcon from '../../../../../assets/img/more-for-you/kuda-airtime-icon.inline.svg'
import BorrowIcon from "../../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg"
import KudaSaveIcon from '../../../../../assets/img/more-for-you/kuda-save-icon.inline.svg'

const entryContent = {
    title: "Send money for free anytime.",
    subtitle: "Your Kuda account comes with 25 free transfers to other banks every month. That’s up to 15,000 naira saved on transfers every year.",
     illustration: <StaticImage src={`../../../../../assets/img/kuda-new-spend.png`} placeholder="blurred" alt="kuda Microfinance Bank Savings, Spend, Budgeting." loading="eager" />
}

const kudaTopFeatures = [{
    icon: <Card />,
    text: "Withdraw cash for payments free of charge at over 3,000 ATMs."
}, {
    icon: <Pay />,
    text: "Get 25 free transfers on the first day of every month."
}, {
    icon: <PaymentLink />,
    text: "Send money free of charge without an account number."
}]

const payNothing = {
    payTitle: "Free transfers will make your life easier.",
    subtitle: "We believe in moving money quickly and free of charge, so you can count on getting 25 free transfers to other banks every month forever.",
    url: "",
    name: "",
    payIllustration: <SendFreeIllustration />
}

const debit = {
    payTitle: (<span>Kuda debit card =<br /> A simpler life</span>),
    subtitle: "You can't avoid spending. That's how you pay for your needs. Pick up your Kuda Visa Card or have us deliver it to your address and never pay a card maintenance fee. ",
    url: "/en-ng/cards/",
    name: "Learn More About Cards",
    payIllustration: <CardNewIllustration />
}

const payBills = {
    payTitle: "Need to pay with cash? Withdraw it for free.",
    subtitle: "Transfers are best but if you need to pay with cash, you can withdraw free of charge with your Kuda Card at over 3,000 ATMs.",
    url: "",
    name: "",
    payIllustration: <CashPayIllustration />
}

const paymentLink = {
    payTitle: "Send money without an account number.",
    subtitle: "There's more than one way to send money for free. Create a Kuda Username for quick transfers to other Kuda Usernames.",
    url: "",
    name: "",
    payIllustration: <Username />
}


const paymentID = {
    payTitle: "Pay online without a debit card.",
    subtitle: "Pay directly from your Kuda account on online stores with Pay ID, no card needed.",
    url: "",
    name: "",
    payIllustration: <PayID />
}


const moreForYou = [
    {
      icon: <KudaAirtimeIcon />,
      title: "Airtime",
      subText: `Buy Airtel, Glo, MTN or 9Mobile airtime directly from your account on your Kuda app.`,
      linkTo: `/en-ng/airtime/`
    },
    {
      icon: <BorrowIcon />,
      title: "Loans",
      subText: `Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.`,
      linkTo: `/en-ng/personal-loan/`
    },
    {
      icon: <KudaSaveIcon />,
      title: "Save",
      subText: `Make saving easier with Spend+Save, Pocket or Fixed savings with up to 15% annual interest.`,
      linkTo: `/en-ng/save/`
    },
  
  ]

const Spend = () => {


    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <EntryFold ctaClass={"desktop-only"} title={entryContent.title} subtitle={entryContent.subtitle} illustration={entryContent.illustration} fallback={<FallbackSpendHeader className="blur isImage" />} isImage={true} />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight title={payNothing.payTitle} subtitle={payNothing.subtitle} illustration={payNothing.payIllustration} name={payNothing.name} url={payNothing.url} />
            <ImageTextLeft title={debit.payTitle} subtitle={debit.subtitle} illustration={debit.payIllustration} name={debit.name} url={debit.url} />
            <ImageTextRight title={payBills.payTitle} subtitle={payBills.subtitle} illustration={payBills.payIllustration} name={payBills.name} url={payBills.url} />
            <ImageTextLeft title={paymentLink.payTitle} subtitle={paymentLink.subtitle} illustration={paymentLink.payIllustration} name={paymentLink.name} url={paymentLink.url} />
            <ImageTextRight title={paymentID.payTitle} subtitle={paymentID.subtitle} illustration={paymentID.payIllustration} name={paymentID.name} url={paymentID.url} />
            <MoreForYouComponent moreForYou={moreForYou}/>
            <CTA />
        </Fragment>
    )
}

export default Spend;
